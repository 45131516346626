import React from 'react'

import img from '../../assest/img15.png'

const SecondComponent = () => {
    return (
        <>
            <div className='shome1'>
                <div className='shome2'>
                    <h3>Why Join Psykode for Social<br/> Networking</h3>
                    <div className='shome3'>
                        <p>It is a long established fact that a reader will be distracted by the readable<br/> content of a page when looking at its layout. The point of using Lorem Ipsum<br/> is that it has a more-or-less normal.</p>
                    </div>
                    <div className='shome4'>
                        <h5>Why it's way better</h5>
                        <span className='shome5'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7202 1.04383C16.6241 1.07955 16.4937 1.15647 16.4304 1.21485C16.3671 1.27319 14.3439 3.50867 11.9344 6.18255C8.68629 9.78714 7.49297 11.0823 7.31918 11.1918C7.04198 11.3665 6.73706 11.3882 6.44468 11.2542C6.34032 11.2064 5.25681 10.2652 3.90029 9.04404C2.3349 7.6349 1.4703 6.8874 1.33804 6.82887C0.713482 6.5524 0.00264216 7.0143 6.89709e-06 7.69833C-0.00053833 7.83927 0.031312 8.02874 0.0707955 8.11943C0.121365 8.23547 1.04098 9.13128 3.17264 11.1409C4.83912 12.712 6.28775 14.0486 6.3918 14.1111C6.67527 14.2812 7.06065 14.272 7.34258 14.0883C7.58216 13.9323 17.748 2.67011 17.8913 2.40199C18.2075 1.81051 17.7986 1.06837 17.1195 1.00121C16.9829 0.987674 16.8264 1.00439 16.7202 1.04383Z" fill="#07142E"></path></svg>
                            <span className='shome6'> It is a long established fact that a reader will be distracted by the readable content</span>
                        </span>
                        <span className='shome5'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7202 1.04383C16.6241 1.07955 16.4937 1.15647 16.4304 1.21485C16.3671 1.27319 14.3439 3.50867 11.9344 6.18255C8.68629 9.78714 7.49297 11.0823 7.31918 11.1918C7.04198 11.3665 6.73706 11.3882 6.44468 11.2542C6.34032 11.2064 5.25681 10.2652 3.90029 9.04404C2.3349 7.6349 1.4703 6.8874 1.33804 6.82887C0.713482 6.5524 0.00264216 7.0143 6.89709e-06 7.69833C-0.00053833 7.83927 0.031312 8.02874 0.0707955 8.11943C0.121365 8.23547 1.04098 9.13128 3.17264 11.1409C4.83912 12.712 6.28775 14.0486 6.3918 14.1111C6.67527 14.2812 7.06065 14.272 7.34258 14.0883C7.58216 13.9323 17.748 2.67011 17.8913 2.40199C18.2075 1.81051 17.7986 1.06837 17.1195 1.00121C16.9829 0.987674 16.8264 1.00439 16.7202 1.04383Z" fill="#07142E"></path></svg>
                            <span className='shome6'> It is a long established fact that a reader will be distracted by the readable content</span>
                        </span>
                    </div>

                    <div className='shome7'>
                        <button>join community</button>
                    </div>
                </div>
                <div className='shome8'>
                    <img src={img} alt="" />
                </div>
            </div>
        </>
    )
}

export default SecondComponent