import React from 'react'
import './Courses.css'

const Courses = () => {
    return (
        <>


        </>
    )
}

export default Courses